/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (apps.css in this case)
// import './styles/app.scss';

// const $ = require('jquery');
import 'jquery';
import 'bootstrap';

const $ = require('jquery');
global.$ = global.jQuery = $;

$(document).ready(function () {
    console.log('Je passe');
    $('[data-toggle="popover"]').popover();
    $('[data-toggle="tooltip"]').tooltip();
});


$(document).on('click', '[data-toggle="lightbox"]', function (event) {
    event.preventDefault();
    $(this).ekkoLightbox({
        alwaysShowClose: false,
    });
});
